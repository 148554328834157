<template>
  <TableSettingsStyled>
    <DurationFormatMolecule
      :widgetSettings="widgetSettings"
      @changeWidgetSettings="$emit('changeWidgetSettings', $event)"
      v-if="showDurationFormatter"
    />
  </TableSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import DurationFormatMolecule from '@/components/Atomic/Molecules/DurationFormatMolecule'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'

const TableSettingsStyled = styled('div')``

export default {
  components: {
    TableSettingsStyled,
    DurationFormatMolecule,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
      required: false,
    },
  },

  computed: {
    selectedDimensionNames() {
      return this.dimensionsSelected.map(({ name }) => name)
    },
    selectedTimeBasedKPIs() {
      return assetDimensionWithDurationFormat.filter(dimension => this.selectedDimensionNames.some(selected => dimension === selected))
    },
    showDurationFormatter() {
      return this.selectedTimeBasedKPIs.length > 0
    },
  },
}
</script>
