var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableSettingsStyled', [_vm.showDurationFormatter ? _c('DurationFormatMolecule', {
    attrs: {
      "widgetSettings": _vm.widgetSettings
    },
    on: {
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.$emit('changeWidgetSettings', $event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }